import { StyleSheet, View } from "react-native";
import React, { useEffect } from "react";
import { screen } from "../State";
import HomeScreen from "./HomeScreen";
import UploadScreen from "./UploadScreen";
import DownloadScreen from "./DownloadScreen";

const Index = () => {
  const DisplayScreen = () => {
    if (screen.use() === "Upload") {
      return <UploadScreen />;
    }
    return <HomeScreen />;
  };

  return (
    <View style={styles.container}>
      {window.location.pathname !== "/" ? (
        <DownloadScreen />
      ) : (
        <DisplayScreen />
      )}
    </View>
  );
};

export default Index;

const styles = StyleSheet.create({
  container: {
    flex: 20,
  },
});
