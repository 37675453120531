import { StyleSheet, Text, View } from "react-native";
import Menu from "./components/Menu";
import Index from "./screens/Index";

export default function App() {
  return (
    <View style={styles.container}>
      <Menu />
      <Index />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
