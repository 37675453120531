import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";

const Menu = () => {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => (window.location.href = window.location.origin)}
    >
      <View style={styles.logoContainer}>
        <MaterialIcons name="backspace" size={32} color="white" />
        <Text style={styles.whiteTxt}>White</Text>
        <Text style={styles.outTxt}>Out</Text>
        <Text style={styles.webTxt}>Web</Text>
      </View>
    </TouchableOpacity>
  );
};

export default React.memo(Menu);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "black",
    padding: 20,
    height: 80,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  logoContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  whiteTxt: {
    color: "white",
    fontSize: 25,
    marginLeft: 10,
    textTransform: "uppercase",
  },
  outTxt: {
    color: "rgba(255,0,0,1)",
    fontSize: 25,
    marginRight: 15,
    fontWeight: "700",
    textTransform: "uppercase",
  },
  webTxt: {
    color: "white",
    fontSize: 25,
    textTransform: "uppercase",
  },
});
