import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";

const CustomButtton = ({ Upload, progressPercent, selectedFile, isDownloadBtn, onPress, placeholder }) => {
  if (isDownloadBtn) {
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={onPress}
      >
          <Text style={styles.txt}>{placeholder}</Text>
      </TouchableOpacity>
    );
  }
    return (
      <TouchableOpacity
        style={styles.container}
        onPress={Upload}
        disabled={(progressPercent > 0) | !selectedFile}
      >
        {progressPercent === 0 ? (
          <Text style={styles.txt}>Start</Text>
        ) : (
          <Text style={styles.txt}>{progressPercent}% Complete</Text>
        )}
      </TouchableOpacity>
    );
};

export default CustomButtton;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "rgba(255,0,0,1)",
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    width: 259,
    height: 40,
  },
  txt: {
    letterSpacing: 1,
    fontSize: 20,
    color: "white",
    textTransform: "uppercase",
    fontWeight: "700",
  },
});
