import { initializeApp } from "firebase/app";

// Optionally import the services that you want to use
// import {...} from "firebase/auth";
// import {...} from "firebase/database";
import { getFirestore } from "firebase/firestore";
// import {...} from "firebase/functions";
import { getStorage } from "firebase/storage";

import { getFunctions } from "firebase/functions";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBjRH91--Xm1npaFrz285yDkjereIcGxvo",
  authDomain: "whiteoutweb.firebaseapp.com",
  projectId: "whiteoutweb",
  storageBucket: "whiteoutweb.appspot.com",
  messagingSenderId: "166195272683",
  appId: "1:166195272683:web:ccf3740a6b6b70491417ae",
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const storage = getStorage(app);

export const functions = getFunctions(app);
