import { ScrollView, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import { getDoc, doc } from "firebase/firestore";
import { db } from "../components/FirebaseFunctions";
import fileDownload from "js-file-download";
import MissingScreen from "./MissingScreen";
import CustomTextInput from "../components/CustomTextInput";
import CustomButtton from "../components/CustomButtton";

const DownloadScreen = () => {
  const [docContent, setDocContent] = useState(null);
  const [docContentExists, setDocContentExists] = useState(null);
  const [password, setPassword] = useState(null);

  useEffect(() => {
    checkFile();
  }, []);

  const checkFile = async () => {
    const docRef = doc(db, "public", window.location.pathname.slice(1));
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setDocContent(docSnap.data());
      setDocContentExists(true);
    } else {
      setDocContent(false);
      setDocContentExists(false);
    }
  };

  const handleDownload = async (url, filename) => {
    // const callableReturnMessage = httpsCallable(functions, "corsResp");

    // callableReturnMessage({ url })
    //   .then((result) => {
    //     console.log(result.data);
    //   })
    //   .catch((error) => {
    //     console.log(`error: ${JSON.stringify(error)}`);
    //   });
    // axios
    //   .get(url, {
    //     responseType: "blob",
    //   })
    //   .then((res) => {
    //     fileDownload(res.data, filename);
    //   });
    await fetch(url, {
      method: "get",
    })
      .then((res) => res.arrayBuffer())
      .then((buffer) => {
        fileDownload(buffer, filename);
      })
      .catch((e) => console.log(e));
  };

  return (
    <View style={styles.container}>
      {docContentExists === true && (
        <View style={styles.content}>
          {docContent.password === password ? (
            <View>
              <Text style={styles.txt}>Access Granted</Text>
              <ScrollView horizontal={true} style={{ maxWidth: 250 }}>
                <Text style={styles.txt}>File Name: {docContent.fileName}</Text>
              </ScrollView>
              <Text style={styles.txt}>
                Destruction Date: {docContent.dateToDestruct}
              </Text>
              <CustomButtton
                isDownloadBtn={true}
                onPress={() =>
                  handleDownload(docContent.downloadURL, docContent.fileName)
                }
                placeholder="Download"
              />
            </View>
          ) : (
            <CustomTextInput
              lbl="Enter Access Password"
              placeholder="Password"
              setState={setPassword}
            />
          )}
        </View>
      )}
      {docContentExists === false && <MissingScreen />}
      {docContentExists === null && (
        <View style={styles.content}>
          <Text>Loading...</Text>
        </View>
      )}
    </View>
  );
};

export default DownloadScreen;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    flexDirection: "row",
    width: "60%",
    height: "50%",
    alignItems: "center",
    justifyContent: "center",
  },
  txt: {
    color: "#121212",
    fontSize: 19,
    marginBottom: 20,
  },
});
