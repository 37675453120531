import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";
import Entypo from "@expo/vector-icons/Entypo";

const UploadFileDropIn = ({ setSelectedFile, selectedFile }) => {
  const changeHandler = (event) => {
    if (event.target.files[0].size < 5368709120) {
      setSelectedFile(event.target.files[0]);
    } else {
      alert("Sorry 5gb files are the max!");
    }
  };

  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => document.getElementById("html_btn").click()}
    >
      <Entypo name="upload" size={32} color="rgba(128,128,128,1)" />
      {selectedFile ? (
        <ScrollView horizontal={true} style={{ maxHeight: 25 }}>
          <Text style={styles.txt}>File: {selectedFile.name}</Text>
        </ScrollView>
      ) : (
        <Text style={styles.txt}>Upload File</Text>
      )}
      <input
        type="file"
        name="file"
        id="html_btn"
        onChange={changeHandler}
        style={{ display: "none" }}
      />
    </TouchableOpacity>
  );
};

export default React.memo(UploadFileDropIn);

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#E6E6E6",
    borderRadius: 10,
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  txt: {
    color: "#121212",
  },
});
