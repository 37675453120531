import { Button, StyleSheet, Text, View } from "react-native";
import React, { useEffect, useState } from "react";
import CustomTextInput from "../components/CustomTextInput";
import UploadFileDropIn from "../components/UploadFileDropIn";
import DateSelector from "../components/DateSelector";
import CustomButtton from "../components/CustomButtton";
import FileUrlDisplay from "../components/FileUrlDisplay";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { db, storage } from "../components/FirebaseFunctions";
import { collection, addDoc } from "firebase/firestore";
import { useResponsiveWidth } from "react-native-responsive-dimensions";

const HomeScreen = () => {
  const [password, setPassword] = useState(null);
  const [dateToDestruct, setDateToDestruct] = useState(1);
  const [selectedFile, setSelectedFile] = useState();
  const [progressPercent, setProgressPercent] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);
  const width = useResponsiveWidth(100);

  const Upload = () => {
    const { v4: uuidV4 } = require("uuid");
    const uuid = uuidV4();

    const AddToDb = async ({ downloadURL }) => {
      const today = new Date();
      const newDate = new Date(today.getTime() + 86400000 * dateToDestruct);
      const yyyy = newDate.getFullYear();
      let mm = newDate.getMonth() + 1; // Months start at 0!
      let dd = newDate.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "/" + mm + "/" + yyyy;

      const docRef = await addDoc(collection(db, "public"), {
        password,
        dateToDestruct: formattedToday,
        downloadURL,
        reference: `files/${uuid}${file.name}`,
        fileName: file.name,
      });
      setFileUrl(`${window.location.href}${docRef.id}`);
    };

    const file = selectedFile;

    if (!file) return;

    const storageRef = ref(storage, `files/${uuid}${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgressPercent(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          AddToDb({ downloadURL });
        });
      }
    );
  };

  const styles = StyleSheet.create(
    width > 700
      ? {
          container: {
            backgroundColor: "white",
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
          },
          content: {
            flexDirection: "row",
            width: "60%",
            height: "50%",
            alignItems: "center",
            justifyContent: "space-between",
            marginBottom: 20,
          },
          row1: {
            height: "100%",
            width: "50%",
          },
          row2: {
            height: "100%",
            width: "50%",
            alignItems: "flex-end",
          },
          row1Content: {
            height: "80%",
            width: "100%",
            justifyContent: "space-between",
          },
        }
      : {
          container: {
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: 25,
            paddingBottom: 25,
          },
          content: {
            width: "90%",
            height: "100%",
            alignItems: "center",
          },
          row1: {
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          },
          row2: {
            width: "65%",
            height: 200,
            alignItems: "center",
            justifyContent: "center",
          },
          row1Content: {
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: 20,
          },
        }
  );

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        <View style={styles.row1}>
          <View style={styles.row1Content}>
            <CustomTextInput
              lbl="Choose File Access Password"
              placeholder="Enter Password or Leave Blank"
              setState={setPassword}
            />
            <DateSelector
              dateToDestruct={dateToDestruct}
              setDateToDestruct={setDateToDestruct}
            />
            <CustomButtton
              Upload={Upload}
              progressPercent={progressPercent}
              selectedFile={selectedFile}
            />
          </View>
        </View>
        <View style={styles.row2}>
          <UploadFileDropIn
            setSelectedFile={setSelectedFile}
            selectedFile={selectedFile}
          />
        </View>
      </View>
      <FileUrlDisplay fileUrl={fileUrl} />
    </View>
  );
};

export default HomeScreen;
