import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import React from "react";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";

const FileUrlDisplay = ({ fileUrl }) => {
  const CopyToClipboard = () => {
    navigator.clipboard.writeText(fileUrl);
  };

  const Share = () => {
    if (navigator.share) {
      navigator
        .share({
          // Title that occurs over
          // web share dialog
          title: "WhiteOutWeb",

          text: "Access a secret",

          // URL to share
          url: fileUrl,
        })
        .catch((err) => {
          // Handle errors, if occured
          console.log("Error while using Web share API:");
          console.log(err);
        });
    }
  };

  return (
    <View style={styles.container}>
      <Text style={styles.txt}>File URL: </Text>
      <ScrollView horizontal={true}>
        <Text style={styles.txt}>{fileUrl}</Text>
      </ScrollView>
      <TouchableOpacity onPress={CopyToClipboard} disabled={!fileUrl}>
        <MaterialCommunityIcons
          name="content-copy"
          size={35}
          color="rgba(128,128,128,1)"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={Share} disabled={!fileUrl}>
        <MaterialCommunityIcons
          name="share"
          size={35}
          color="rgba(128,128,128,1)"
        />
      </TouchableOpacity>
    </View>
  );
};

export default FileUrlDisplay;

const styles = StyleSheet.create({
  container: {
    width: "60%",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: 10,
    backgroundColor: "rgba(230, 230, 230,1)",
    alignItems: "center",
    borderRadius: 6,
  },
  txt: {
    color: "#121212",
    fontSize: 16,
  },
});
