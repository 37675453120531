import { StyleSheet, Text, View, TextInput } from "react-native";
import React from "react";

type Props = {
  lbl: string;
  placeholder: string;
  setState: (val: string) => void;
};

const CustomTextInput = ({ lbl, placeholder, setState }: Props) => {
  return (
    <View>
      {lbl !== null && <Text style={styles.txt}>{lbl}</Text>}
      <TextInput
        placeholder={placeholder}
        style={styles.input}
        onChangeText={(val) => setState(val)}
      />
    </View>
  );
};

export default React.memo(CustomTextInput);

const styles = StyleSheet.create({
  input: {
    width: 259,
    height: 40,
    color: "#121212",
    backgroundColor: "rgba(230, 230, 230,1)",
    fontSize: 15,
    padding: 10,
    marginBottom: 20,
  },
  txt: {
    color: "#121212",
    fontSize: 19,
    marginBottom: 20,
  },
});
