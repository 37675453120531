import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import React from "react";
import Entypo from "@expo/vector-icons/Entypo";

const DateSelector = ({ dateToDestruct, setDateToDestruct }) => {
  return (
    <View>
      <Text style={styles.txt}>How Many Days Until Deletion?</Text>
      <View style={styles.dayInput}>
        <TouchableOpacity
          disabled={dateToDestruct === 1}
          onPress={() => setDateToDestruct((prevState) => prevState - 1)}
        >
          <Entypo name="squared-minus" size={35} color="rgba(128,128,128,1)" />
        </TouchableOpacity>
        <Text>
          {dateToDestruct} {dateToDestruct === 1 ? "Day" : "Days"}
        </Text>
        <TouchableOpacity
          disabled={dateToDestruct === 3}
          onPress={() => setDateToDestruct((prevState) => prevState + 1)}
        >
          <Entypo name="squared-plus" size={35} color="rgba(128,128,128,1)" />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default DateSelector;

const styles = StyleSheet.create({
  dayInput: {
    width: 259,
    height: 40,
    color: "#121212",
    backgroundColor: "rgba(230, 230, 230,1)",
    fontSize: 15,
    padding: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  txt: {
    color: "#121212",
    fontSize: 19,
    marginBottom: 20,
  },
});
